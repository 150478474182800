<template>
    <div class="min-h-screen bg-gray-900 background-image">
        <div class="flex ml-10 mr-10 py-10 mx-auto w-auto h-12">
            <img class="flex-initial h-8 w-auto" src="@/assets/images/logo/logo-white.svg" alt="Scorelit logo">
            <div class="flex-auto "></div>
        </div>
        <div class=" flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "Guest"
}
</script>

<style scoped>
    .background-image {
        /* https://www.w3schools.com/html/html_images_background.asp */
        background: #000000;
        background-image: url('~@/assets/images/background/header.jpg'); /* fallback */
        background-image: linear-gradient(#000000, #0000), url('~@/assets/images/background/header.jpg');
        background-repeat: no-repeat;
        background-position: center; 
        background-attachment: fixed;
        background-size: cover;
    }
</style>